<template>
  <div id="instagram" ref="instagram" v-html="content"></div>
</template>

<script>
import api from '@/services/api'

export default {
  name: 'InstagramFeed',
  props: {
    userName: {
      type: String
    }
  },
  data() {
    return {
      content: ''
    }
  },
  created() {
    this.load()
  },
  updated() {
    this.load()
  },
  methods: {
    load() {
      api({
        url: `/feedR/${this.userName.replace(/^[@]/, '')}`,
        method: 'get'
      })
        .then(({ data }) => {
          const loaded = typeof data.error === 'undefined'
          this.content = loaded
            ? data.content
            : `<span style="color: #f44336">${data.error}</span>`
          this.$emit('loaded', loaded)
        })
        .catch(console.error)
    }
  }
}
</script>
