<template>
  <app-container class="home">
    <h1>Procurando um widget do Instagram como este?</h1>
    <p>
      Você está no lugar certo!
      <router-link to="/login" tag="button">Entrar</router-link>
    </p>
    <instagram-feed user-name="laranjanaofunfa"></instagram-feed>
  </app-container>
</template>

<script>
import AppContainer from '@/components/AppContainer'
import InstagramFeed from '@/components/InstagramFeed'

export default {
  name: 'Home',
  components: { AppContainer, InstagramFeed }
}
</script>

<style lang="sass" scoped>
p
  margin-bottom: 16px
a
  color: #2C699D
  font-weight: bold
  margin-left: 12px
</style>
