import axios from 'axios'

const host =
  process.env.NODE_ENV === 'production'
    ? 'https://instagramec.futurasistemas.com.br'
    : 'http://localhost/instagram'

export default axios.create({
  baseURL: `${host}/api/public/index.php/`
  // timeout: 5000
})
